import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

export const PasswordSuggestionHelperDefaultOptions = {
  defaultSelector: '.wdf-password-suggestion'
}

export class PasswordSuggestionHelperComponent extends PewComponent {

  init() {
    this.$generateButton = this.element.find('.wdf-generate-password-suggestion');
    this.$inputPassword = this.element.closest('.password-wrap').find('input[type=password]');
    this.$form = this.element.closest('form');

    window.onload = () => {
      this.initPasswordSuggestion();
      this.initPasswordStrength();
    }
  }

  initPasswordSuggestion() {
    this.$generateButton.click(() => {

      this.$inputPassword.attr('type', 'text');

      // Based on native wordpress process
      // see web/wp/wp-admin/js/user-profile.js for detail
      wp.ajax.post('generate-password')
        .done((data) => {
          if (this.$inputPassword.length > 0) {
            this.$inputPassword.val(data);
            this.evaluatePasswordStrength(this.$inputPassword.val());
          }
        });
    });
  }

  initPasswordStrength() {
    this.$inputPassword.on('keyup', () => {
      // Native wordpress password evaluation
      // Need to have zxcvbn available as global object
      this.evaluatePasswordStrength(this.$inputPassword.val());

    });
  }

  /**
   * Native wordpress password evaluation
   * Need to have zxcvbn available as global object
   *
   * @param {string} password
   */
  evaluatePasswordStrength(password)
  {
    this.element.find('.wdf-password-strength-result').removeClass(['bad', 'good', 'strong', 'short']);
    const strength = wp.passwordStrength.meter(password, wp.passwordStrength.userInputDisallowedList(), password);

    switch (strength) {
      case -1:
        this.element.find('.wdf-password-strength-result').addClass('bad').html(pwsL10n.unknown);
        break;
      case 2:
        this.element.find('.wdf-password-strength-result').addClass('bad').html(pwsL10n.bad);
        break;
      case 3:
        this.element.find('.wdf-password-strength-result').addClass('good').html(pwsL10n.good);
        break;
      case 4:
        this.element.find('.wdf-password-strength-result').addClass('strong').html(pwsL10n.strong);
        break;
      case 5:
        this.element.find('.wdf-password-strength-result').addClass('short').html(pwsL10n.mismatch);
        break;
      default:
        this.element.find('.wdf-password-strength-result').addClass('short').html(pwsL10n['short']);
    }

    this.allowFormSubmission(strength, password);
  }

  allowFormSubmission(strength, password) {
    if (strength >= 3 || !password) {
      this.$form.find('[type=submit]').attr('disabled', false);
    } else {
      this.$form.find('[type=submit]').attr('disabled', true);
    }
  }
}

window.pew.addRegistryEntry({
  key: 'wdf-password-suggestion',
  domSelector: PasswordSuggestionHelperDefaultOptions.defaultSelector,
  classDef: PasswordSuggestionHelperComponent
});
