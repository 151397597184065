(function ($, ns) {
  "use strict";
  /**
   * init module scripts, relative to its context (multiple context of the same module may exist in a page)
   * @param context wraper div of the module
   */
  var espaceRestreint = function (context) {
    this.$context = (context instanceof jQuery) ? context : $(context);
    this.init();
  };

  espaceRestreint.prototype = {
    init: function () {
      this.registerLoginFormIfExists();
      this.registerSignupFormIfExists();
      this.registerForgotPwdFormIfExists();
      this.registerResetPwdFormIfExists();
    },
    registerLoginFormIfExists: function () {
      var $loginForm = this.$context.find('form.er-login-form');
      if ($loginForm.length) {
        this.registerFormSubmit(
          $loginForm,
          function ($form, res) {
            if (res && res.data && res.data.data && res.data.data.redirect_to) {
              setTimeout(()=>{
                document.location = res.data.data.redirect_to;
              },50);
            }
          }
        );
      }
    },
    registerSignupFormIfExists: function () {
      var $signupForm = this.$context.find('form.er-signup-form');
      if ($signupForm.length) {
        this.registerFormSubmit(
          $signupForm,
          function ($form, res) {
            if (res && res.data && res.data.redirect_to) {
              document.location = res.data.redirect_to;
            }
          }
        );
      }
    },
    registerForgotPwdFormIfExists: function(){
      var $forgotPwdForm = this.$context.find('form.er-forgotpwd-form');
      if($forgotPwdForm.length) {
        this.registerFormSubmit($forgotPwdForm);
      }
    },
    registerResetPwdFormIfExists: function(){
      var $resetPwdForm = this.$context.find('form.er-resetpwd-form');
      if($resetPwdForm.length) {
        this.registerFormSubmit($resetPwdForm);
      }
    },
    registerFormSubmit: function ($form, successCb, errorCb) {
      var t = this;
      $form.on('submit', function (e) {
        e.preventDefault();

        //check form validity
        if ($(this).valid && !$(this).valid()) {
          return false;
        }

        t.clearNotifications(t.$context);

        //ajax submit
        $form.addClass('loading').find('input[type="submit"]').attr('disabled', 'disabled');
        $.post($(this).attr('action'), $(this).serializeArray(), function (res) {
          if (res && res.code && res.code === 200) {
            t.onSubmitSuccess(res, $form, successCb);
          } else {
            t.onSubmitError(res, $form, errorCb);
          }
          $form.removeClass('loading').find('input[type="submit"]').removeAttr('disabled', 'disabled');
        }).fail(function (err) {
          t.onSubmitError(err, $form, errorCb);
          $form.removeClass('loading').find('input[type="submit"]').removeAttr('disabled', 'disabled');
        });
      });
    },
    onSubmitSuccess: function (res, $form, successCb) {
      this.notify('success', res.data.msg, this.$context);
      document.title += ' ' + res.data.msg;
      successCb && successCb($form, res);
    },
    onSubmitError: function (res, $form, errorCb) {
      var t = this,
        notifType = res && res.code && res.code === 202 ? 'info' : 'error',
        notifMsg = res && res.data && res.data.msg ? res.data.msg : 'Error';

      this.notify(notifType, notifMsg, t.$context, 6000);

      document.title += ' ' + notifMsg;
      if (res && res.data && res.data.errors) {
        for (var i in res.data.errors) {
          var errorMsg = '<label class="label-error" style="display: inline;">' + res.data.errors[i][0] + '</label>';
          var $errorredInput = $form.find('input[name=' + i + ']');
          $errorredInput.attr('aria-invalid', true).after(errorMsg);
          $errorredInput.parent().addClass('has-error');
        }
      }
      errorCb && errorCb($form, res);
    },
    notify: function (type, msg, $context, duration) {
      var EventManager = window.EventManager || $(document);
      EventManager.trigger('notification', {
        type: type,
        msg: msg,
        dest: $context,
        focus: true
      });
    },
    clearNotifications($dest) {
      let $alerts = $dest.find('.alert');
      if ($alerts.length) {
        $alerts.fadeOut(400, function () {
          $(this).remove();
        });
      }
    }
  };

  if (ns && ns.app) {
    ns.app.registerModule('espaceRestreint', espaceRestreint);
  } else {
    window.pew.addRegistryEntry({key: 'wdf-plugin-espace-restreint', domSelector: '.module-espaceRestreint', classDef: espaceRestreint});
  }

})(jQuery, window.wonderwp);
