(function ($, ns) {
  "use strict";
  /**
   * init module scripts, relative to its context (multiple context of the same module may exist in a page)
   * @param context wraper div of the module
   */
  var ThemeEspaceRestreint = function (context) {
    this.$context = (context instanceof jQuery) ? context : $(context);
    this.init();
  };

  var parentPrototype = window.pew.getRegistryEntry('wdf-plugin-espace-restreint').classDef.prototype;
  ThemeEspaceRestreint.prototype = jQuery.extend({}, parentPrototype);

  ThemeEspaceRestreint.prototype.onSubmitError = function (res, $form, errorCb) {
    if (res && res.status && res.status === 403) {
      res.data = {'msg': 'Le mot de passe ou identifiant ne correspond pas'};
      setTimeout(function () {
        window.location.reload();
      }, 1500);
    }

    var t = this,
      notifType = res && res.code && res.code === 202 ? 'info' : 'error',
      notifMsg = res && res.data && res.data.msg ? res.data.msg : 'Error';

    this.notify(notifType, notifMsg, t.$context, 6000);

    document.title += ' ' + notifMsg;
    $form.find('.label-error').remove();
    $form.find('.has-error').removeClass('has-error');
    $form.find('[aria-invalid=true]').removeAttr('aria-invalid');
    if (res && res.data && res.data.errors) {
      for (var i in res.data.errors) {
        var errorMsg = '<label class="label-error" style="display: inline;">' + res.data.errors[i].join('<br />') + '</label>';
        var $errorredInput = $form.find('input[name=' + i + ']');
        $errorredInput.attr('aria-invalid', true).after(errorMsg);
        $errorredInput.parent().addClass('has-error');
      }
    }
    errorCb && errorCb($form, res);
  };

  window.pew.addRegistryEntry({
    key: 'wdf-plugin-espace-restreint',
    domSelector: '.module-espaceRestreint',
    classDef: ThemeEspaceRestreint
  });


})(jQuery, window.wonderwp);
